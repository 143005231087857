
import { Component, Vue } from "vue-property-decorator";
import Wysiwyg from "@/components/reusable/Wysiwyg.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Icon from "@/components/reusable/Icon.vue";
import DimEditTable from "@/components/product/DimEditTable.vue";
import AttrEditTable from "@/components/product/AttrEditTable.vue";
import { ProductModel, ProductModelRequest } from "@/models/product";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import ProductMenu from "@/components/product/ProductMenu.vue";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import ProductService from "@/services/product_service";
import UIkit from "uikit";
import { EventBus } from "@/events/index";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import { CategoryModel } from "@/models/category";
import { MfrModel } from "@/models/mfr";
import RelatedProductModal from "@/components/product/RelatedProductModal.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, sameAs, not } from "vuelidate/lib/validators";
import { noFwdSlash, noSpaces } from "@/validators/custom";
import { AttributeModel } from "@/models/attribute";
import { DimensionModel } from "@/models/dimension";
import { APIResponse } from "@/models/api_res";
import CategoryService from "@/services/category_service";
import { AuthError, NotFoundError } from "@/services/error_service";
import ImagePicker from "@/components/reusable/ImagePicker.vue";
import MfrService from "@/services/mfr_service";
import AttributeService from "@/services/attribute_service";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
import AssetFileSelector from "@/components/asset/AssetFileSelector.vue";
import { AssetModel } from "@/models/asset";
import { ProductSchematic } from "@/models/product_schematic";
import { getImage } from "@/utility/asset";

Component.registerHooks(["beforeRouteLeave"]);
@Component({
  components: {
    Wysiwyg,
    FormInput,
    Icon,
    DimEditTable,
    AttrEditTable,
    Autocomplete,
    ProductMenu,
    RelatedProductModal,
    ImagePicker,
    AssetFileSelector,
  },
})
export default class ProductEditor extends Vue {
  protected showWarningModal = false;
  protected productService = new ProductService();
  protected mfrService = new MfrService();
  protected categoryList: CategoryModel[] = [];
  protected mfrList: MfrModel[] = [];
  protected attributeService = new AttributeService();
  protected getImage = getImage;
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddBusinessUnit))
  setBusinessUnit: any;

  protected justPosted = false;
  protected id = 0;
  protected categoryService = new CategoryService();
  protected deleteData: ProductModel[] = [];
  protected isNew = false;
  protected isDupe = false;
  public originalProduct = {} as ProductModel;
  protected product = {
    diagram: {} as AssetModel,
    downloads: [] as AssetModel[],
    related: [] as ProductModel[],
    is_hidden: false,
    manufacturer: {},
    categories: [] as CategoryModel[],
    schematics: [] as AssetModel[],
  } as ProductModel;
  protected sortableDownloads: AssetModel[] = [];
  protected sortableSchematics: AssetModel[] = [];
  protected imageKey = 0;

  @Validations()
  validations() {
    if (!this.isDupe) {
      return {
        product: {
          item_number: { required, noFwdSlash, noSpaces },
          display_name: { required },
        },
      };
    } else {
      return {
        product: {
          item_number: {
            required,
            noFwdSlash,
            noSpaces,
            dupe: not(sameAs(() => this.originalProduct.item_number)),
          },
          display_name: {
            required,
            dupe: not(sameAs(() => this.originalProduct.display_name)),
          },
        },
      };
    }
  }

  async created() {
    if (!this.$route.params.id) {
      this.isNew = true;
      this.product.business_unit = this.businessUnit;
      if (this.$route.query.dupe) {
        this.isDupe = true;
        this.id = parseInt(this.$route.query.dupe as string, 10);
        await this.getSingleProduct();
      } else if (this.$route.query.mfr) {
        // Vue.set(this.product, 'manufacturer', {} as MfrModel)
        this.product.manufacturer = await this.getMfr(
          parseInt(this.$route.query.mfr as string, 10)
        );
      } else if (this.$route.query.parent) {
        // Vue.set(this.product, 'manufacturer', {} as MfrModel)
        const parent = await this.getCategory(
          parseInt(this.$route.query.parent as string, 10)
        );
        this.product.categories!.push(parent);
      }
    } else {
      this.id = parseInt(this.$route.params.id, 10);
      await this.getSingleProduct();
    }
    if (this.product.categories?.length !== 0 && this.product.business_unit !== this.businessUnit) {
      this.setBusinessUnit(this.product.business_unit);
    }
  }
  mounted() {
    EventBus.$on("sendImage", (files: AssetModel[]) => {
      this.product.image = files[0];
      this.showWarningModal = true;
      this.imageKey += 1;
    });
    EventBus.$on("sendDiagram", (files: AssetModel[]) => {
      this.product.diagram = files[0];
      this.showWarningModal = true;
    });
    EventBus.$on("sendDocs", (files: AssetModel[]) => {
      if (
        this.product.downloads &&
        this.product.downloads.length &&
        this.product.downloads.length > 0
      ) {
        this.product.downloads = [...this.product.downloads, ...files];
        this.sortableDownloads = [...this.sortableDownloads, ...files];
      } else {
        this.product.downloads = files;
        this.sortableDownloads = files;
      }
      this.showWarningModal = true;
    });
    EventBus.$on("sendSchematics", (files: AssetModel[]) => {
      if (
        this.product.schematics &&
        this.product.schematics.length &&
        this.product.schematics.length > 0
      ) {
        this.product.schematics = [...this.product.schematics, ...files];
        this.sortableSchematics = [...this.sortableSchematics, ...files];
      } else {
        this.product.schematics = files;
        this.sortableSchematics = files;
      }
      this.showWarningModal = true;
    });
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: ProductModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "product" },
        { height: "auto", adaptive: true }
      );
    });
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    EventBus.$off("sendImage");
    EventBus.$off("sendDiagram");
    EventBus.$off("sendDocs");
    EventBus.$off("sendSchematics");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal",
    ];
    modals.forEach((selector) => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }
  protected autoCompleteFetchCat(value: string): void {
    this.getCategories({ q: value, leaf: true, bu: this.businessUnit });
  }

  protected removeImage(): void {
    (this.product.image as ProductModelRequest["image"]) = 0;
    this.showWarningModal = true;
    this.imageKey += 1;
  }

  protected removeDiagram(): void {
    (this.product.diagram as ProductModelRequest["diagram"]) = 0;
    this.showWarningModal = true;
  }

  protected showDocPicker(): void {
    this.$modal.show(
      AssetFileSelector,
      { header: "Select Download Files", image: false, callback: "sendDocs" },
      {
        adaptive: true,
        height: "100%",
        width: "875px",
        classes: "asset-file-selector",
      }
    );
  }

  protected showDiagramPicker(): void {
    this.$modal.show(
      AssetFileSelector,
      { header: "Select Diagram Image", image: true, callback: "sendDiagram" },
      {
        adaptive: true,
        height: "100%",
        width: "875px",
        classes: "asset-file-selector",
      }
    );
  }

  protected showSchematicPicker(): void {
    this.$modal.show(
      AssetFileSelector,
      { header: "Select Schematic Files", image: false, callback: "sendSchematics" },
      {
        adaptive: true,
        height: "100%",
        width: "875px",
        classes: "asset-file-selector",
      }
    );
  }

  protected removeRelatedProduct(index: number): void {
    this.product.related!.splice(index, 1);
    this.showWarningModal = true;
  }

  protected removeDownload(index: number): void {
    const sortedDocIndex = this.sortableDownloads.findIndex(
      doc => doc.id === this.product.downloads![index].id
    );
    this.sortableDownloads!.splice(sortedDocIndex, 1);
    this.product.downloads = this.sortableDownloads;
    this.showWarningModal = true;
  }

  protected removeSchematic(index: number): void {
    const sortedDocIndex = this.sortableSchematics.findIndex(
      doc => doc.id === this.product.schematics![index].id
    );
    this.sortableSchematics!.splice(sortedDocIndex, 1);
    this.product.schematics = this.sortableSchematics;
    this.showWarningModal = true;
  }

  protected autoCompleteFetchMfr(value: string): void {
    this.getMfrs({ q: value, bu: this.businessUnit });
  }

  protected async getMfrs(optionsObject?: {}): Promise<void> {
    try {
      const res: APIResponse = await this.mfrService.getMfrs(optionsObject);
      this.mfrList = res.results;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected showFilePicker(): void {
    this.$modal.show(
      AssetFileSelector,
      {},
      {
        adaptive: true,
        height: "100%",
        width: "875px",
        classes: "asset-file-selector",
      }
    );
  }

  protected async getCategories(optionsObject?: {}): Promise<void> {
    try {
      const res: APIResponse = await this.categoryService.getCategories(
        optionsObject
      );
      this.categoryList = res.results;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected updateAttrs(data: AttributeModel[]) {
    this.product.attributes = data;
  }

  protected updateDims(data: DimensionModel[]) {
    this.product.dimensions = data;
  }

  protected toggleRelatedModal(): void {
    UIkit.modal(document.getElementById("related-modal") as HTMLElement).show();
  }

  protected async getMfr(id: number): Promise<MfrModel> {
    let mfr = {} as MfrModel;
    try {
      const res = await this.mfrService.getSingleMfr(id);
      mfr = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return mfr;
  }

  protected saveKeypress(): void {
    this.save();
  }

  protected get cdn(): string {
    return process.env.VUE_APP_CDN_URL;
  }

  protected async getRelatedProducts(): Promise<void> {
    this.setLoading(true);
    const related: ProductModel[] = [];
    for (let i = 0; i < this.product.related!.length; i++) {
      const res = await this.returnSingleProduct(this.product.related![i].id);
      related.push(res);
    }

    this.product.related = related;
    this.setLoading(false);
  }

  protected async getCategory(id: number): Promise<CategoryModel> {
    let cat = {} as CategoryModel;
    try {
      const res = await this.categoryService.getSingleCategory(id);
      cat = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return cat;
  }

  protected get website(): string {
    if (this.businessUnit == 'bmh') {
      return process.env.VUE_APP_C5_URL;
    } else {
      return process.env.VUE_APP_WORKMASTER_URL;
    }
  }

  protected updateCategory(item: { id: number; display_name: string }) {
    if (!this.product.categories) {
      this.product.categories = [];
    }
    const cat = this.categoryList.filter((cat) => {
      if (item.id === cat.id) {
        return cat;
      }
    });
    if (!cat[0].is_leaf) {
      EventBus.$emit(
        "showError",
        `Cannot link product to non-leaf category, <strong>${cat[0].display_name}</strong>.`
      );
    } else {
      this.product.categories.push(cat[0]);
    }
  }

  protected async setRelatedProduct(item: ProductModel): Promise<void> {
    const product = await this.returnSingleProduct(item.id);
    if (this.product.related && this.product.related.length) {
      this.product.related.push(product);
    } else {
      this.product.related = [product];
    }
    this.showWarningModal = true;
  }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    this.showWarningModal = false;
    try {
      await this.productService.deleteProduct(id);
      this.$router.push({
        path: "/product",
        query: { deleted: encodeURI(`${this.product.title}`), type: "product" },
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected updateMfr(item: { id: number; display_name: string }) {
    this.product.manufacturer = {} as MfrModel;
    const mfr = this.mfrList.filter((mfr) => {
      if (item.id === mfr.id) {
        return mfr;
      }
    });
    this.product.manufacturer = mfr[0];
  }

  protected cancel(): void {
    this.$router.go(-1);
  }

  protected save(): void {
    this.setLoading(true);
    this.$v.product.$touch();
    if (!this.$v.$invalid) {
      this.showWarningModal = false;
      this.finalizeTitle();
      EventBus.$emit("checkAttributes"); // emits to both EditTables here (Attr & Dim).
      this.makeRequest();
    } else {
      this.$nextTick(() => {
        (this.$refs.requiredFields as HTMLDivElement).scrollIntoView();
      });
    }
    this.setLoading(false);
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.showWarningModal) {
      UIkit.modal
        .confirm(
          `
    <div class="uk-modal-header uk-flex uk-flex-middle">
      <div class="uk-flex-none">
        <span
          uk-icon="icon: warning; ratio:1.5;"
          class="red no-hover uk-margin-small-right"
        ></span>
      </div>
      <div>
        <h2 class="uk-modal-title uk-margin-remove">
          You have not saved your changes!
        </h2>
      </div>
    </div>
    <div class="uk-modal-body">
      Would you like to continue without saving your changes?
    </div>`
        )
        .then(
          function () {
            next();
          },
          function () {
            next(false);
          }
        );
    } else {
      next();
    }
  }

  protected showWarning(isVisible: boolean): void {
    this.showWarningModal = isVisible;
  }

  protected makeRequest(): void {
    if (this.isNew) {
      this.postNew();
    } else {
      this.saveExisting();
    }
  }

  protected finalizeTitle(): void {
    if (!this.product.title) {
      this.product.title = this.product.display_name as string;
    }
  }

  protected async getSingleProduct(): Promise<void> {
    this.setLoading(true);
    try {
      const res: ProductModel = await this.productService.getSingleProduct(
        this.id
      );
      this.product = res;
      this.originalProduct = { ...res };
      if (this.product.related && this.product.related.length > 0) {
        await this.getRelatedProducts();
      }
      this.reorgProductSchematics();
      this.setSortableDocuments();
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof NotFoundError) {
        this.$router.replace({
          name: "NotFound",
          query: { error: encodeURI(err.message) },
        });
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async returnSingleProduct(id: number): Promise<ProductModel> {
    let res = {} as ProductModel;
    try {
      const data: ProductModel = await this.productService.getSingleProduct(id);
      res = data;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return res;
  }

  /**
   * The returning schematics format doesn't play well with our AssetModel type.
   * If not restructured to the AssetModel type, resubmitting any existing
   * schematics will produce a database error that cannot be fixed through the UI.
   * 
   * Method is to be ran after all occurrences of product API calls.
   * @returns this.product.schematics : AssetModel[]
   */
  protected reorgProductSchematics(): void {
    const res: AssetModel[] = [];
     if (this.product.schematics && this.product.schematics.length > 0) {
      const arr = this.product.schematics as ProductSchematic[];
      for (const element of arr) {
        const asset: any = {};
        asset.id = element.asset_id;
        asset.filename = element.filename;
        asset.path = element.path;
        asset.size_h = element.asset.size_h;
        asset.link = element.asset.link;
        res.push(asset as AssetModel);
      }
    }      
    this.product.schematics = res;
  }

  protected setSortableDocuments(): void {
    if (this.product.downloads) {
      this.sortableDownloads = this.product.downloads;
    }
    if (this.product.schematics) {
      this.sortableSchematics = this.product.schematics;
    }
    //@ts-ignore
    const { on, ready } = UIkit.util;
    ready(() => {
      //@ts-ignore
      on(document.body, "moved", item => {
        const parentElement = item.detail[1].parentElement as HTMLElement;
        const isSortedDownloadsItem = parentElement.classList.contains(
          "downloads-docs"
        );
        const isSortedSchematicsItem = parentElement.classList.contains(
          "schematics-docs"
        );
        const reorderedElementsOfProductDocs = parentElement.children as HTMLCollection;
        const reorderedDocs = [];
        for (let i = 0; i < reorderedElementsOfProductDocs.length; i++) {
          const docsElement = reorderedElementsOfProductDocs[i];
          if (isSortedDownloadsItem && this.product.downloads) {
            reorderedDocs.push(
              this.product.downloads[parseInt(docsElement.id)]
            );
          } else if (isSortedSchematicsItem && this.product.schematics) {
            reorderedDocs.push(
              this.product.schematics[parseInt(docsElement.id)]
            );
          }
        }
        if (reorderedDocs.length > 0) {
          if (isSortedDownloadsItem) {
            this.sortableDownloads = reorderedDocs;
          } else if (isSortedSchematicsItem) {
            this.sortableSchematics = reorderedDocs;
          }
          this.showWarningModal = true;
        }
      });
    });
  }

  protected async postNew(): Promise<void> {
    const req = this.buildRequest();
    delete req.id;
    try {
      const res = await this.productService.createNewProduct(req);
      this.product = res;
      this.$router.push({
        path: "/product/edit/" + res.id,
        query: { created: encodeURI(`${this.product.title}`), type: "product" },
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async getAttributes(optionsObject?: {}): Promise<AttributeModel[]> {
    let results;
    try {
      const res: APIResponse = await this.attributeService.getAttributes(
        optionsObject
      );
      results = res.results;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return results;
  }

  protected async saveExisting(): Promise<void> {
    const req = this.buildRequest();
    try {
      await this.productService.saveProduct(
        req,
        this.product.id as number
      );
      await this.getSingleProduct();
      if (this.$route.query.created) {
        this.$router.push({ query: {} });
      }
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Product <strong>${this.product.display_name}</strong> has been saved. <a href="${this.website}/products${this.product.url}" target="_blank">View Live</a>`,
        []
      );
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * IMPORTANT: You may need to add/manipulate any new fields in this method!
   *
   * Modifying object structure into what API expects on POST/PATCH requests
   *
   */
  protected buildRequest(): ProductModelRequest {
    const req: any = { ...this.product };
    delete req.manufacturer;
    req.bu = this.businessUnit;
    if (this.product.image && typeof this.product.image !== "number") {
      req.image = (this.product.image as AssetModel).id;
    }
    if (this.product.diagram && this.product.diagram.id) {
      req.diagram = this.product.diagram.id;
    } else {
      req.diagram = 0;
    }
    if (this.product.downloads && this.product.downloads.length > 0) {
      const dlIds = this.sortableDownloads.map(doc => doc.id);
      req.downloads = this.removeDupe(dlIds as number[]);
    } else {
      req.downloads = [];
    }

    if (this.product.schematics && this.product.schematics.length > 0) {
      const schematicIds = this.sortableSchematics.map(
        schematic => schematic.id
      );
      req.schematics = this.removeDupe(schematicIds as number[]);
    } else {
      req.schematics = [];
    }

    if (this.product.categories && this.product.categories.length < 1) {
      req.categories = [];
    } else if (this.product.categories && this.product.categories.length > 0) {
      const catIds = this.product.categories.map((cat) => {
        return cat.id;
      });
      req.categories = this.removeDupe(catIds as number[]);
    }
    if (this.product.manufacturer && this.product.manufacturer.id) {
      req.manufacturer_id = this.product.manufacturer.id;
    }
    if (this.product.related && this.product.related.length < 1) {
      req.related = [];
    } else if (this.product.related && this.product.related.length > 0) {
      const relatedIds = this.product.related.map((item) => {
        return item.id;
      });
      req.related = this.removeDupe(relatedIds as number[]);
    }
    if (this.product.dimensions && this.product.dimensions.length) {
      req.dimensions = this.product.dimensions.filter((dim) => {
        return dim.label;
      });

      req.dimensions.forEach((dim: DimensionModel) => {
        delete dim.id;
        delete dim.idx;
      });
      if (req.dimensions?.length < 1) {
        req.dimensions = [];
      }
    }
    if (this.product.attributes?.length) {
      req.attributes = this.product.attributes.filter((attr) => {
        return attr.display_name;
      });
      if (
        req.attributes.length < 1 &&
        !this.product.attributes[0].display_name
      ) {
        req.attributes = [];
      }
    }

    return req;
  }

  /**
   * Remove duplicate ids from arrays sent into POST/PATCH /product requests.
   * Duplicates in any data will throw a 409
   * Relevant for arrays of ids {number[]}: related, categories, downloads
   *
   * */
  protected removeDupe(arr: number[]): number[] {
    const result: number[] = [];
    arr.forEach((item: number, index: number) => {
      if (arr.indexOf(item) == index) result.push(item);
    });
    return result;
  }

  get editor3(): string {
    return "product_desc";
  }

  /**
   * Method that reads updates from the Quill text editors <Wsywig>
   * Each Wsywig is identified by "editor{number}" (computed) and mapped to a specific data field
   *
   * NOTE: There is only 1 wsywig on this page, so dataName doesn't need to be read
   */
  protected receiveUpdates(dataName: string, data: any): void {
    this.product.description = data;
  }
}
